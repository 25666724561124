import * as tsx from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { VContainer, VCard, VCardTitle, VCardText, VDivider } from "vuetify/lib";

interface Pole {
    asset_id: string;
    asset_name: string;
    asset_type: string;
    cabinet_area_id: number;
    permanent_id: string;
    is_inspectable: boolean;
}
interface InspectionTransitions {
    asset_permanent_id: string;
    entered_by: string;
    entered_on: string;
    is_inspectable_new_value: Boolean;
    changeset_id?: number;
    override_reason?: string;
}
interface PoleInspections {
    asset_permanent_id: string;
    entered_by: string;
    entered_on: string;
    id: number;
    inspection_date: string;
}
interface PoleProperties {
    asset_permanent_id: string;
    entered_by: string;
    entered_on: string;
    id: number;
    date_installed: string;
}
export interface PoleData {
    pole: Pole;
    pole_inspection_transition: InspectionTransitions[];
    pole_inspections: PoleInspections[];
    pole_properties: PoleProperties[];
}

@Component({
    components: {
        VContainer,
        VCard,
        VCardTitle,
        VCardText,
        VDivider,
    },
})
export default class PoleInfoFromAssetManagement extends tsx.Component<{ poleData: PoleData }> {
    @Prop({ required: true }) poleData!: PoleData;

    private generatePoleDetails(pole: Pole): JSX.Element {
        return (
            <div>
                <p>
                    <strong>Asset ID:</strong> {pole.asset_id}
                </p>
                <p>
                    <strong>Asset Name:</strong> {pole.asset_name}
                </p>
                <p>
                    <strong>Inspectable:</strong> {pole.is_inspectable ? "Yes" : "No"}
                </p>
            </div>
        );
    }

    private generateInspectionTransitions(pole_inspection_transition: InspectionTransitions[]): JSX.Element {
        if (!pole_inspection_transition.length) {
            return <p>This pole does not have records of inspection transitions.</p>;
        }

        return (
            <ul>
                {pole_inspection_transition.map(transition => {
                    const enteredOnDate = new Date(transition.entered_on);
                    const formattedDate = enteredOnDate.toLocaleString("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                    });

                    return (
                        <li key={transition.asset_permanent_id} style={{ marginBottom: "16px" }}>
                            <div>
                                <strong>Entered on:</strong> {formattedDate}
                            </div>
                            <div>
                                <strong>Entered by:</strong> {transition.entered_by}
                            </div>
                            <div>
                                <strong>Is inspectable?</strong> {transition.is_inspectable_new_value ? "Yes" : "No"}
                            </div>
                            {transition.override_reason && (
                                <div style={{ marginTop: "8px" }}>
                                    <strong>Reason for entry:</strong> {transition.override_reason}
                                </div>
                            )}
                            {transition.changeset_id && (
                                <div>
                                    <strong>Changeset ID:</strong> {transition.changeset_id}
                                </div>
                            )}
                        </li>
                    );
                })}
            </ul>
        );
    }

    private generateInspections(inspections: PoleInspections[]): JSX.Element {
        if (!inspections.length) {
            return <p>No inspections available.</p>;
        }
        return (
            <ul style={{ marginBottom: "16px" }}>
                {inspections.map(inspection => (
                    <li key={inspection.id}>
                        <strong style={{ margin: "0px 4px 0px 4px" }}>Inspection date:</strong>
                        {new Date(inspection.inspection_date).toLocaleDateString("en-GB")}{" "}
                        <strong style={{ margin: "0px 4px 0px 4px" }}>Entered by:</strong> {inspection.entered_by}
                    </li>
                ))}
            </ul>
        );
    }

    private generateProperties(properties: PoleProperties[]): JSX.Element {
        if (!properties.length) {
            return <p>No properties available.</p>;
        }
        return (
            <ul style={{ marginBottom: "16px" }}>
                {properties.map(property => (
                    <li key={property.id}>
                        <strong style={{ margin: "0px 4px 0px 4px" }}>Date Installed:</strong>{" "}
                        {new Date(property.date_installed).toLocaleDateString("en-GB")}
                        <strong style={{ margin: "0px 4px 0px 16px" }}>Entered by:</strong> {property.entered_by}
                    </li>
                ))}
            </ul>
        );
    }

    render(): JSX.Element {
        const { pole, pole_inspection_transition, pole_inspections, pole_properties } = this.poleData;

        return (
            <v-container>
                <v-card>
                    <v-card-title>
                        <h2>Pole Information</h2>
                    </v-card-title>
                    <VDivider />
                    <v-card-text>
                        {this.generatePoleDetails(pole)}
                        <h3>Inspection Transitions</h3>
                        {this.generateInspectionTransitions(pole_inspection_transition)}
                        <h3>Inspections</h3>
                        {this.generateInspections(pole_inspections)}
                        <h3>Properties</h3>
                        {this.generateProperties(pole_properties)}
                    </v-card-text>
                </v-card>
            </v-container>
        );
    }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/src/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-fdb5f3cc/pole-info-from-asset-management.tsx" });